<template>
    <!-- Appointment Start -->
    <div
      class="container-fluid my-5 py-5 wow fadeIn"
      data-wow-delay="0.1s"
      style="background: linear-gradient(rgba(0, 155, 60), rgb(0 155 60 / 47%)), url(/img/dykes.png) 
        center center no-repeat;background-size: cover;"
      v-if="params"
    >
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
            <h1 class="display-6 text-white mb-5">
              {{ params.quoteTitle }}
            </h1>
            <p class="text-white mb-5">
              {{ params.quoteText }}
            </p>
            <div class="bg-white rounded p-3">
              <div class="d-flex align-items-center bg-primary rounded p-3">
                <img
                  class="flex-shrink-0 rounded-circle me-3"
                  src="img/profile.png"
                  alt=""
                />
                <h5 class="text-white mb-0">Call Us: {{ params.phone }}</h5>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div class="bg-white rounded p-5">
              <form @submit.prevent="submitQuote">
                <div class="row g-3">
                    <div class="col-sm-6">
                        <div class="form-floating">
                            <select class="form-control" v-model="record.type" required>
                                <option 
                                    :value="null" 
                                    disabled
                                >
                                    --- Select One ---
                                </option>
                                <option
                                    v-for="product in products"
                                    :key="product.id"
                                    :value="product.title"
                                >
                                    {{ product.title }}
                                </option>
                                <option value="others">Others</option>
                            </select>
                            <label for="gname">Choose Insurance type *</label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="gname"
                            placeholder="Full Name"
                            v-model="record.name"
                            required
                        />
                        <label for="gname">Your Name *</label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-floating">
                        <input
                            type="email"
                            class="form-control"
                            id="gmail"
                            placeholder="Email Address *" 
                            v-model="record.email"
                            required
                        />
                        <label for="gmail">Your Email *</label>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-floating">
                        <input
                            type="text"
                            class="form-control"
                            id="cname"
                            placeholder="Phone Number"
                            v-model="record.phone"
                        />
                        <label for="cname">Your Phone Number</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-floating">
                        <textarea
                            class="form-control"
                            placeholder="Leave a message here"
                            id="message"
                            style="height: 80px"
                            v-model="record.message"
                            required
                        ></textarea>
                        <label for="message">Message</label>
                        </div>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-primary py-3 px-5" type="submit">
                            {{ (this.isloading ? 'Please Wait...' : 'Get A Quote') }}
                        </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Appointment End -->
</template>

<script>
    import swal from 'sweetalert2'
    import { useReCaptcha } from "vue-recaptcha-v3";
    import { mapActions } from 'vuex';

    export default {
        name: 'QuoteForm',
        setup() {
            const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
            const recaptcha = async () => {
                await recaptchaLoaded();
                await executeRecaptcha("login");
            };
            return {
                recaptcha,
            };
        },
        props: ['params', 'services'],
        data() {
            return {
                record: {
                    type: null,
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                },
                isloading: false
            }
        },
        computed: {
            products() {
                return this.services && this.services.contents ? this.services.contents : [];
            }
        },
        methods: {
            ...mapActions(['sendQuote']),
            submitQuote() {                
                let check = this.validateInputs();
                if(check) {
                    this.isloading = true;
                    this.$recaptcha("login").then(token => {
                        this.record.token = token;
                        this.sendQuote(this.record).then((res) => {// get homepage data
                            this.isloading = false;
                            if(res.status == 200  && res.data.status) { // set data
                                this.emptyRecord();
                                swal.fire('Quote Sent!', res.data.msg, 'success');
                            } else {
                                this.isLoadingBlade = false;
                                swal.fire('Error Occurred', res.data.msg, 'error');
                            }
                        }).
                        catch(() => {
                            this.isLoadingBlade = false;
                            this.isloading = false;
                            let msg = 'Error occurred while sending quote, kindly reload page and try again...';
                            swal.fire('Error Occurred', msg, 'error');
                        });
                    }).catch(() => {
                        this.isloading = false;
                        let msg = 'Error occurred while sending quote, kindly reload page and try again...';
                        swal.fire('Error Occurred', msg, 'error');
                    });
                }                
            },
            validateInputs() {
                let status = this.record.type && this.record.name && this.record.email && this.record.message;
                if(status) {
                    return true
                } else {
                    let msg = 'Empty Field(s), fill the necessary fields and try again...';
                    swal.fire('Error Occurred', msg, 'error');
                    return false;
                }
            },
            emptyRecord() {
                return this.record = {
                    type: null,
                    name: null,
                    email: null,
                    phone: null,
                    message: null
                };
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>