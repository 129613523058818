<template>
    <nav 
        aria-label="Page navigation" 
        v-if="pagination.hasPagination"
        style="margin-top: 30px"
    >
        <ul class="pagination">
            <li 
                v-for="(link, index) in pagination.links"
                :key="index"
                :class="'page-item ' + (link.active ? 'active' : '') + (link.url ? '' : ' disabled')"
            >
                <a 
                    class="page-link" 
                    href="javascript:void(0)" tabindex="-1"
                    v-if="index == 0"
                    @click="paginate((link.active ? null : link.url))"
                >
                    &laquo;
                </a>
                <a 
                    class="page-link" 
                    href="javascript:void(0)"
                    @click="paginate((link.active ? null : link.url))"
                    v-if="((index != 0) && (index+1 != pagination.links.length))"
                >
                    {{ link.label }}
                    <span class="sr-only">(current)</span>
                </a>

                <a 
                    class="page-link" 
                    href="javascript:void(0)"
                    @click="paginate((link.active ? null : link.url))"
                    v-if="(index+1 == pagination.links.length)"
                >
                    &raquo;
                </a> 
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'Pagination',
        props: ['paginate', 'pagination']
    }
</script>

<style lang="scss" scoped>

</style>