<template>
    <div v-if="menu && category">
        <!-- Page Header Start -->
        <div
            class="container-fluid py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            :style="'background: url(' + (menu && menu.image ? menu.image.url : '/img/carousel-1.jpg') +
                     ') center center no-repeat;background-size: cover;'"
        >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">{{ category.title }}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ menu.title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->

        <!-- Team Start -->
        <div 
            class="container-xxl py-5"
            v-if="records && records.length > 0"
        >
            <div class="container">
                <div class="row g-4">
                    <div 
                        class="col-lg-3 col-md-6 wow fadeInUp" 
                        data-wow-delay="0.1s"
                        v-for="record in records"
                        :key="record.id"
                    >
                        <div class="team-item rounded">
                            <img 
                                class="img-fluid" 
                                v-if="record.image"
                                :src="record.image.url" 
                                :alt="record.image.description" 
                            />
                            <div class="text-center p-4">
                                <h5>{{ record.title }}</h5>
                                <span>{{ record.description }}</span>
                            </div>
                            <div class="team-text text-center bg-white p-4">
                                <router-link
                                    :to="'/menu/' + category.route + '/' + record.slug"
                                >
                                    <h5>{{ record.title }}</h5>
                                </router-link>
                                <p>{{ record.description }}</p>
                                <small>{{ formatDate(record.created_at) }}</small>
                                <div class="d-flex justify-content-center">
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.twitter"
                                        :href="record.twitter"
                                    >
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.facebook"
                                        :href="record.facebook"
                                    >
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.linkedin"
                                        :href="record.linkedin"
                                    >
                                        <i class="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Pagination 
                    :pagination="pagination"
                    :paginate="paginate"
                />
            </div>
        </div>
        <!-- Team End -->
    </div>
</template>

<script>
    import moment from 'moment'
    import Pagination from '../layouts/Pagination.vue'
    
    export default {
        name: 'GridTemplate',
        props: ['menu', 'category', 'contents', 'paginate'],
        components: {
            Pagination
        },
        computed: {
            formatDate(){
                return (date)=>{
                    return date ? moment(date).format('MMM Do, YYYY') : 'N/A';
                }
            },
            records() {
                return this.contents.data;
            },
            pagination() {
                let data = this.contents;
                return {
                    hasPagination: (data.prev_page_url || data.next_page_url),
                    links: data.links
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>