<template>
    <div>
        <!-- Footer Start -->
        <div
            class="container-fluid bg-dark footer mt-5 pt-5 wow fadeIn"
            data-wow-delay="0.1s"
            v-if="params"
        >
        <div class="container py-5">
            <div class="row g-5">
            <div class="col-lg-3 col-md-6">
                <h1 class="text-white mb-4">
                <img
                    class="img-fluid me-3"
                    src="/img/logo.png"
                    :alt="getsiteName"
                />
                </h1>
                <p v-if="params.description">{{ params.description }}</p>
                <div class="d-flex pt-2">
                <a 
                    class="btn btn-square me-1" 
                    :href="params.twitter"
                    v-if="params.twitter"
                >
                    <i class="fab fa-twitter"></i>
                </a>
                <a 
                    class="btn btn-square me-1" 
                    :href="params.facebook"
                    v-if="params.facebook"
                >
                    <i class="fab fa-facebook-f"></i>
                </a>
                <a 
                    class="btn btn-square me-0"
                    :href="params.linkedin"
                    v-if="params.linkedin"
                >
                    <i class="fab fa-linkedin-in"></i>
                </a>
                </div>
            </div>
            <div class="col-lg-3 col-md-6">
                <h5 class="text-light mb-4">Address</h5>
                <p v-if="params.address">
                    <i class="fa fa-map-marker-alt me-3"></i>
                    {{ params.address }}
                </p>
                <p><i class="fa fa-phone-alt me-3" v-if="params.phone"></i>{{ params.phone }}</p>
                <p><i class="fa fa-envelope me-3" v-if="params.email"></i>{{ params.email }}</p>
            </div>
            <div class="col-lg-3 col-md-6">
                <h5 class="text-light mb-4">Quick Links</h5>
                <router-link to="/" class="btn btn-link">Home</router-link>
                <router-link to="/contact" class="btn btn-link">Contact Us</router-link>
                <router-link to="/blog" class="btn btn-link">Blog</router-link>
                <router-link to="/privacy-policy" class="btn btn-link">Privacy Policy</router-link>
                <router-link to="/get-quote" class="btn btn-link">Get Quote</router-link>
            </div>
            <div class="col-lg-3 col-md-6">
                <h5 class="text-light mb-4">Newsletter</h5>
                <!-- <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p> -->
                <div class="position-relative mx-auto" style="max-width: 400px">
                <input
                    class="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Your email"
                />
                <button
                    type="button"
                    class="btn btn-secondary py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                    SignUp
                </button>
                </div>
            </div>
            </div>
        </div>
        <div class="container-fluid copyright">
            <div class="container">
            <div class="row">
                <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy; <a href="#">{{ getsiteName }}</a>, All Right Reserved.
                </div>
                <div class="col-md-6 text-center text-md-end">
                    Designed By <a href="https://maspatconsulting.com" target="_blank">MASPAT Consulting</a>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!-- Footer End -->

        <!-- Back to Top -->
        <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top">
            <i class="bi bi-arrow-up"></i>
        </a>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'Footer',
        props: ['params', 'services'],
        computed: {
            ...mapGetters(['getsiteName']),
            contents() {
                return this.services && this.services.contents ? this.services.contents : false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>