import { createRouter, createWebHistory } from 'vue-router'
import Homepage from './components/pages/Homepage'
import OtherContentPage from './components/pages/OtherContentPage'
import MenuPage from './components/pages/MenuPage'
import OtherContentCategory from './components/pages/OtherContentCategory'
import OtherContentSinglePage from './components/pages/OtherContentSinglePage'
import ContactPage from './components/pages/ContactPage'
import SubMenuRoutePage from './components/pages/SubMenuRoutePage'
import MenuRoutePage from './components/pages/MenuRoutePage'
import QuotePage from './components/pages/QuotePage'

const routes = [
    { path: '/', name: 'Homepage', component: Homepage },
    { path: '/pages/:category/:slug', name: 'OtherContentPage', component: OtherContentPage },
    { path: '/menu/:category/:slug', name: 'MenuPage', component: MenuPage },
    { path: '/category/:slug', name: 'OtherContentCategory', component: OtherContentCategory },
    { path: '/privacy-policy', name: 'privacy-policy', component: OtherContentSinglePage },
    { path: '/contact', name: 'contact', component: ContactPage },
    { path: '/get-quote', name: 'Quote', component: QuotePage },
    { path: '/:menu/:slug', name: 'sub-menu-page', component: SubMenuRoutePage },
    { path: '/:slug', name: 'menu-page', component: MenuRoutePage }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes` 
}); 

export default router;
