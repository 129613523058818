<template>
    <!-- Team Start -->
    <div class="container-xxl py-5" v-if="category && contents && contents.length > 0">
      <div class="container">
        <div class="text-center mx-auto" style="max-width: 500px">
          <h1 class="display-6 mb-5" v-if="category.description">{{ category.description }}</h1>
        </div>
        <div class="row g-4">
          <div 
            class="col-lg-3 col-md-6 wow fadeInUp" 
            data-wow-delay="0.1s"
            v-for="record in contents"
            :key="record.id"
          >
            <div class="team-item rounded">
              <img 
                class="img-fluid" 
                :src="record.image.url" 
                :alt="record.image.description" 
                v-if="record && record.image"
              />
              <div class="text-center p-4">
                <h5>{{ record.title }}</h5>
                <span>{{ record.description }}</span>
              </div>
              <div class="team-text text-center bg-white p-4">
                <h5>{{ record.title }}</h5>
                <p>{{ record.description }}</p>
                <div class="d-flex justify-content-center">
                  <a 
                    class="btn btn-square btn-light m-1" 
                    v-if="record.twitter"
                    :href="record.twitter"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a 
                    class="btn btn-square btn-light m-1" 
                    v-if="record.facebook"
                    :href="record.facebook"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                  <a 
                    class="btn btn-square btn-light m-1" 
                    v-if="record.linkedin"
                    :href="record.linkedin"
                  >
                    <i class="fab fa-linkedin-in"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>


          <!-- <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item rounded">
              <img class="img-fluid" src="img/team-2.jpg" alt="" />
              <div class="text-center p-4">
                <h5>Full Name</h5>
                <span>Designation</span>
              </div>
              <div class="team-text text-center bg-white p-4">
                <h5>Full Name</h5>
                <p>Designation</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-youtube"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="team-item rounded">
              <img class="img-fluid" src="img/team-3.jpg" alt="" />
              <div class="text-center p-4">
                <h5>Full Name</h5>
                <span>Designation</span>
              </div>
              <div class="team-text text-center bg-white p-4">
                <h5>Full Name</h5>
                <p>Designation</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-youtube"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div class="team-item rounded">
              <img class="img-fluid" src="img/team-4.jpg" alt="" />
              <div class="text-center p-4">
                <h5>Full Name</h5>
                <span>Designation</span>
              </div>
              <div class="team-text text-center bg-white p-4">
                <h5>Full Name</h5>
                <p>Designation</p>
                <div class="d-flex justify-content-center">
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-youtube"></i
                  ></a>
                  <a class="btn btn-square btn-light m-1" href=""
                    ><i class="fab fa-linkedin-in"></i
                  ></a>
                </div>
              </div>
            </div>
          </div> -->


        </div>
      </div>
    </div>
    <!-- Team End -->
</template>

<script>
    export default {
        name: 'Teams',
        props: ['teams', 'params'],
        computed: {
            category() {
                return this.teams && this.teams.category ? this.teams.category : false;
            },
            contents() {
              let array = this.teams && this.teams.contents ? this.teams.contents : false;
              return array && array.length > 0 ? array.slice(0, 4) : false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>