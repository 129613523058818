import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from "vue-recaptcha-v3"

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueReCaptcha, { siteKey: "6LchfjcfAAAAADbCTdR8sgJp3Jw6hF2g8eTYBAGu" })
app.mount('#app')
