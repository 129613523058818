<template>
    <!-- Service Start -->
    <div 
        class="container-xxl py-5"
        v-if="services && services.category && services.contents && services.contents.length > 0">
    >
      <div class="container">
        <div class="text-center mx-auto" style="max-width: 500px">
          <h1 class="display-6 mb-5">
            {{ services.category.description }}
          </h1>
        </div>
        <div class="row g-4 justify-content-center">

            <div 
                class="col-lg-3 col-md-6 wow fadeInUp" 
                data-wow-delay="0.1s"
                v-for="(record, index) in services.contents"
                :key="record.id"
            >
                <div class="service-item rounded h-100 p-5">
                <div class="d-flex align-items-center ms-n5 mb-4">
                    <div
                    class="service-icon flex-shrink-0 bg-primary rounded-end me-4"
                    >
                    <img
                        class="img-fluid"
                        :src="'/img/icon/icon-'+ (leadingZero(index+1)) + '-light.png'"
                        :alt="record.title"
                    />
                    </div>
                    <h4 class="mb-0">{{ record.title }}</h4>
                </div>
                <p class="mb-4">
                    {{ record.description }}
                </p>
                <router-link
                    :to="'/pages/' + services.category.slug + '/' + record.slug"
                    class="btn btn-light px-3"
                >
                    Read More
                </router-link>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- Service End -->
</template>

<script>
    export default {
        name: 'Services',
        props: ['services', 'params'],
        methods: {
            leadingZero (n) {// add leading zeros
                return (n < 10) ? ("0" + n) : n;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>