<template>
    <!-- Carousel Start -->
    <div 
        class="container-fluid p-0 mb-5 wow fadeIn" 
        data-wow-delay="0.1s"
        v-if="sliders && sliders.length > 0"
    >
      <div id="header-carousel" class="carousel slide carousel-fade" data-bs-ride="carousel">
        <div class="carousel-inner">

            
            <div
                :class="'carousel-item ' + (index == 0 ? 'active' : '')" 
                v-for="(slider, index) in sliders"
                :key="slider.id"
            >
                <img 
                    class="w-100" 
                    :src="slider.image.url"
                    :alt="slider.image.description" 
                    v-if="slider.image"
                />
                <div class="carousel-caption">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <h1 class="display-3 text-dark mb-4 animated slideInDown text-white">
                                    {{ slider.title }}
                                </h1>
                                <p class="fs-5 text-body mb-5">
                                    <span class="text-white">{{ slider.description }}</span>
                                </p>
                                <router-link
                                    to="/get-quote"
                                    class="btn btn-success py-3 px-5"
                                >
                                    Get A Quote Now
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->
</template>

<script>
    export default {
        name: 'Slider',
        props: ['sliders']
    }
</script>

<style lang="scss" scoped>

</style>