<template>
    <!-- Facts Start -->
    <div class="container-fluid overflow-hidden my-5 px-lg-0" v-if="params">
      <div class="container facts px-lg-0">
        <div class="row g-0 mx-lg-0">
          <div 
            class="col-lg-6 facts-text wow fadeIn" 
            data-wow-delay="0.1s"
            style="background: linear-gradient(rgba(0, 155, 60), rgb(0 155 60 / 47%)), url(/img/dykes.png) 
                center right no-repeat;background-size: cover;"
          >
            <div class="h-100 px-4 ps-lg-0">
              <h1 class="text-white mb-4" v-if="params.factsTitle">{{ params.factsTitle }}</h1>
              <p class="text-light mb-5" v-if="params.factsText">{{ params.factsText }}</p>
              <router-link
                to="/contact"
                class="align-self-start btn btn-secondary py-3 px-5"
              >
                Contact Us
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 facts-counter wow fadeIn" data-wow-delay="0.5s">
            <div class="h-100 px-4 pe-lg-0">
              <div class="row g-5">
                <div class="col-sm-6">
                    <h1 
                        class="display-5" 
                        data-toggle="counter-up" 
                        v-if="params.clientsCount"
                    >
                        {{ params.clientsCount }}
                    </h1>
                    <p class="fs-5 text-primary">Happy Clients</p>
                </div>
                <div class="col-sm-6">
                    <h1 
                        class="display-5" 
                        data-toggle="counter-up"
                        v-if="params.projectsCount"
                    >
                        {{ params.projectsCount }}
                    </h1>
                    <p class="fs-5 text-primary">Claims Recovered</p>
                </div>
                <div class="col-sm-6">
                    <h1 
                        class="display-5" 
                        data-toggle="counter-up"
                        v-if="params.awardsCount"
                    >
                        {{ params.awardsCount }}
                    </h1>
                    <p class="fs-5 text-primary">Partners</p>
                </div>
                <div class="col-sm-6">
                    <h1 
                        class="display-5" 
                        data-toggle="counter-up"
                        v-if="params.teamCount"
                    >
                        {{ params.teamCount }}
                    </h1>
                    <p class="fs-5 text-primary">Team Members</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facts End -->
</template>

<script>
    export default {
        name: 'Facts',
        props: ['params']
    }
</script>

<style lang="scss" scoped>

</style>