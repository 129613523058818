<template>
    <div>
        <!-- Topbar Start -->
        <div
            class="container-fluid bg-dark text-white-50 py-2 px-0 d-none d-lg-block"
            v-if="params"
        >
            <div class="row gx-0 align-items-center">
                <div class="col-lg-7 px-5 text-start">
                <div class="h-100 d-inline-flex align-items-center me-4">
                    <small class="fa fa-phone-alt me-2"></small>
                    <small class="text-white" v-if="params.phone">{{ params.phone }}</small>
                </div>
                <div class="h-100 d-inline-flex align-items-center me-4">
                    <small class="far fa-envelope-open me-2"></small>
                    <small class="text-white" v-if="params.email">{{ params.email }}</small>
                </div>
                <div class="h-100 d-inline-flex align-items-center me-4">
                    <small class="far fa-clock me-2"></small>
                    <small class="text-white" v-if="params.timing">Mon - Fri : {{ params.timing }}</small>
                </div>
                </div>
                <div class="col-lg-5 px-5 text-end">
                <div class="h-100 d-inline-flex align-items-center">
                    <a 
                        class="text-white-50 ms-4" 
                        :href="params.facebook"
                        v-if="params.facebook"
                    >
                        <i class="fab fa-facebook-f text-white"></i>
                    </a>
                    <a 
                        class="text-white-50 ms-4" 
                        :href="params.twitter"
                        v-if="params.twitter"
                    >
                        <i class="fab fa-twitter text-white"></i>
                    </a>
                    <a 
                        class="text-white-50 ms-4" 
                        :href="params.linkedin"
                        v-if="params.linkedin"
                    >
                        <i class="fab fa-linkedin-in text-white"></i>
                    </a>
                    <!-- <a class="text-white-50 ms-4" href=""
                    ><i class="fab fa-instagram"></i
                    ></a> -->
                </div>
                </div>
            </div>
        </div>
        <!-- Topbar End -->

        <!-- Navbar Start -->
        <nav
        class="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5"
        >
            <router-link
                to="/"
                class="navbar-brand d-flex align-items-center"
            >
                <h1 class="m-0">
                    <img
                        class="img-fluid me-3"
                        src="/img/logo.png"
                        :alt="getsiteName"
                    />
                </h1>
            </router-link>
            <button
                type="button"
                class="navbar-toggler"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav mx-auto bg-light rounded pe-4 py-3 py-lg-0">
                    <router-link
                        to="/"
                        class="nav-item nav-link active"
                    >
                        Home
                    </router-link>
                    <div 
                        class="nav-item dropdown"
                        v-for="menu in menus"
                        :key="menu.id"
                        v-show="menu.sub_menus && menu.sub_menus.length > 0"
                    >
                        <a
                            href="javascript:void(0)"
                            class="nav-link dropdown-toggle"
                            data-bs-toggle="dropdown"
                        >
                            {{ menu.title }} 
                        </a>
                        <div class="dropdown-menu bg-light border-0 m-0">
                            <router-link
                                class="dropdown-item"
                                v-for="sub_menu in menu.sub_menus"
                                :key="sub_menu.id"
                                :to="'/'+menu.route + '/' +sub_menu.route"
                            >
                                {{ sub_menu.title }}
                            </router-link>
                        </div>
                    </div>
                    <router-link
                        class="nav-item nav-link"
                        v-for="menu in menus"
                        :key="menu.id"
                        v-show="menu.sub_menus && menu.sub_menus.length == 0 && (menu.page 
                                || (menu.contents && menu.contents.length > 0))"
                        :to="'/'+menu.route"
                    >
                        {{ menu.title }}
                    </router-link>
                    <router-link
                        to="/contact"
                        class="nav-item nav-link"
                    >
                        Contact Us
                    </router-link>
                </div>
            </div>
            <router-link
                to="/get-quote"
                class="btn btn-primary px-3 d-none d-lg-block"
            >
                Get A Quote
            </router-link>
        </nav>
        <!-- Navbar End -->
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'Header',
        props: ['menus', 'params'],
        computed: {
            ...mapGetters(['getsiteName'])
        }
    }
</script>

<style lang="scss" scoped>

</style>