<template>
    <div>

        <div v-if="! isLoadingBlade && ! errorPage">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
            />

            <!-- this is the slider -->
            <Slider 
                :sliders="sliders"
            />

            <!-- this is the About Us -->
            <About 
                :params="params"
            />
            
            <!-- our Facts included here -->
            <Facts 
                :params="params"
            /> 

            <!-- this is the Features -->
            <Features 
                :params="params"
            />

            <!-- this is the our services -->
            <Services 
                :params="params"
                :services="services"
            />

            <!-- this is the Quote Form -->
            <QuoteForm 
                :params="params"
                :services="services"
            />

            <!-- our Teams included here -->
            <Teams 
                :teams="teams"
                :params="params"
            /> 

            <!-- our Testimonials included here -->
            <Testimonials 
                :testimonials="testimonials"
                :params="params"
            />

            <!-- Footer included here -->
            <Footer 
                :params="params"
                :services="services"
            />
        </div>
        

        <div class="row" v-if="isLoadingBlade">
            <!-- Spinner Start -->
            <div
                id="spinner"
                class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            >
                <div class="spinner-grow text-primary" role="status"></div>
            </div>
            <!-- Spinner End -->
        </div>

        <ErrorPage 
            :menus="menus"
            :params="params"
            :services="services"
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Slider from '../layouts/sliders/Slider.vue'
    import About from '../layouts/homepage/About.vue'
    import Features from '../layouts/homepage/Features.vue'
    import Facts from '../layouts/homepage/Facts.vue'
    import QuoteForm from '../layouts/homepage/QuoteForm.vue'
    import Testimonials from '../layouts/homepage/Testimonials.vue'
    import Teams from '../layouts/homepage/Teams.vue'
    import Footer from '../layouts/Footer.vue'
    import Services from '../layouts/homepage/Services.vue'
    import ErrorPage from '../layouts/ErrorPage.vue'

    export default {
        name: 'Homepage',
        components: {
            Header,
            Slider,
            About,
            Features,
            Facts,
            QuoteForm,
            Testimonials,
            Teams,
            Footer,
            Services,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                sliders: [],
                services: [],
                testimonials: [],
                teams: []
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.isLoadingBlade = true;
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.sliders = data.sliders;// set sliders
                this.services = data.services;// get services
                this.teams = data.teams;// get teams
                this.testimonials = data.testimonials; // get testimonials
                setTimeout(() => { this.isLoadingBlade = false; }, 2000)
                setTimeout(() => { this.loadScripts(); }, 2100)
            },
            loadScripts() {
                // Facts counter
                $('[data-toggle="counter-up"]').counterUp({
                    delay: 10,
                    time: 2000
                });


                // Testimonials carousel
                $(".testimonial-carousel").owlCarousel({
                    autoplay: true,
                    smartSpeed: 1000,
                    items: 1,
                    dots: false,
                    loop: true,
                    nav: true,
                    navText : [
                        '<i class="bi bi-chevron-left"></i>',
                        '<i class="bi bi-chevron-right"></i>'
                    ]
                });
            }
        },
        created() {
            this.loadHomepage();
        }
    }
</script>

<style lang="scss" scoped>

</style>