<template>
    <div v-if="record && category">
        <!-- Page Header Start -->
        <div
            class="container-fluid py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            :style="'background: url(' + (record && record.image ? record.image.url : '/img/carousel-1.jpg') +
                     ') center center no-repeat;background-size: cover;'"
        >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">{{ record.title }}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item">{{ category.title }}</li>
                    <li class="breadcrumb-item active" aria-current="page">{{ record.title }}</li>
                </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->
        
        <!-- Features Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-5">
                    <div 
                        :class="'col-lg-' + (record && record.image ? 6 : 12) + ' wow fadeInUp'" 
                        data-wow-delay="0.1s"
                    >
                        <h1 class="display-6 mb-5">{{ record.title }}</h1>
                        <p class="mb-4" v-html="record.content"></p>
                    </div>
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div
                            class="position-relative rounded overflow-hidden h-100"
                            style="min-height: 400px"
                        >
                        <img
                            class="position-absolute w-100 h-100"
                            :src="record.image.url" 
                            :alt="record.image.description"
                            style="object-fit: cover"
                            v-if="record && record.image"
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Features End -->
    </div>
</template>

<script>
    export default {
        name: 'PageTemplate',
        props: ['record', 'category', 'service', 'params'],
        computed: {
            services() {
                return this.service && this.service.contents ? this.service.contents : [];
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>