<template>
    <div v-if="page && category">
        <!-- Page Header Start -->
        <div
            class="container-fluid py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            :style="'background: url(' + (page && page.image ? page.image.url : '/img/carousel-1.jpg') +
                     ') center center no-repeat;background-size: cover;'"
        >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">{{ category.title }}</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol class="breadcrumb mb-0">
                        <li class="breadcrumb-item">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">{{ page.title }}</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->
        
        <!-- Features Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-5">
                    <div 
                        :class="'col-lg-' + (page && page.image ? 6 : 12) + ' wow fadeInUp'" 
                        data-wow-delay="0.1s"
                    >
                        <h1 class="display-6 mb-5">{{ page.title }}</h1>
                        <p class="mb-4" v-html="page.content"></p>
                    </div>
                    <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                        <div
                            class="position-relative rounded overflow-hidden h-100"
                            style="min-height: 400px"
                        >
                        <img
                            class="position-absolute w-100 h-100"
                            :src="page.image.url" 
                            :alt="page.image.description"
                            style="object-fit: cover"
                            v-if="page && page.image"
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Features End -->

        <!-- Team Start -->
        <div 
            class="container-xxl py-5"
            v-if="records && records.data && records.data.length > 0"
        >
            <div class="container reduce-gap reduce-gap-non-mobile">
                <div class="row g-4">
                    <div 
                        class="col-lg-3 col-md-6 wow fadeInUp" 
                        data-wow-delay="0.1s"
                        v-for="record in records.data"
                        :key="record.id"
                    >
                        <div class="team-item rounded">
                            <img 
                                class="img-fluid" 
                                v-if="record.image"
                                :src="record.image.url" 
                                :alt="record.image.description" 
                                style="width: 300px; height: 300px"
                            />
                            <div class="text-center p-4">
                                <h5>{{ record.title }}</h5>
                                <span>{{ record.description }}</span>
                            </div>
                            <div class="team-text text-center bg-white p-4">
                                <router-link
                                    :to="'/menu/' + category.route + '/' + record.slug"
                                >
                                    <h5>{{ record.title }}</h5>
                                </router-link>
                                <p>{{ record.description }}</p>
                                <div class="d-flex justify-content-center">
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.twitter"
                                        :href="record.twitter"
                                    >
                                        <i class="fab fa-twitter"></i>
                                    </a>
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.facebook"
                                        :href="record.facebook"
                                    >
                                        <i class="fab fa-facebook-f"></i>
                                    </a>
                                    <a 
                                        class="btn btn-square btn-light m-1" 
                                        v-if="record.linkedin"
                                        :href="record.linkedin"
                                    >
                                        <i class="fab fa-linkedin-in"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Team End -->
    </div>
</template>

<script>
    export default {
        name: 'MixedTemplate',
        props: ['content', 'paginate'],
        computed: {
            category() {
                return this.content && this.content.menu ? this.content.menu : {};
            },
            page() {
                return this.content && this.content.menu && this.content.menu.page 
                        ? this.content.menu.page : {};
            },
            records() {
                return this.content && this.content.records ? this.content.records : [];
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>