import { createStore } from 'vuex';
import url from './modules/url/url';
import homepage from './modules/homepage/homepage'
import pages from './modules/pages/pages'


const store = createStore({
    modules: {
        url,
        homepage,
        pages
    }
})

export default store
