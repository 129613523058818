<template>
    <!-- Testimonial Start -->
    <div 
        class="container-xxl py-5" 
        v-if="category && contents && contents.length > 0 && params && params.testimonials"
    >
      <div class="container">
        <div class="text-center mx-auto" style="max-width: 500px">
          <h1 class="display-6 mb-5">{{ params.testimonials }}</h1>
        </div>
        <div class="row g-5">
          <div class="col-lg-3 d-none d-lg-block">
            <div class="testimonial-left h-100">
              <img
                v-for="record in contents"
                :key="record.id"
                class="img-fluid animated pulse infinite"
                :src="record.image.url" 
                :alt="record.image.description" 
                v-show="record && record.image"
              />
            </div>
          </div>
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div class="owl-carousel testimonial-carousel">
                <div 
                    class="testimonial-item text-center"
                    v-for="record in contents"
                    :key="record.id"
                >
                    <img
                        class="img-fluid rounded mx-auto mb-4"
                        :src="record.image.url" 
                        :alt="record.image.description" 
                        v-if="record && record.image"
                    />
                    <p class="fs-5" v-html="record.content"></p>
                    <h5>{{ record.title }}</h5>
                    <span>{{ record.description }}</span>
                </div>
            </div>
          </div>
          <div class="col-lg-3 d-none d-lg-block">
            <div class="testimonial-right h-100">
                <img
                    class="img-fluid animated pulse infinite"
                    v-for="record in contents"
                    :key="record.id"
                    :src="record.image.url" 
                    :alt="record.image.description" 
                    v-show="record && record.image"
                />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonial End -->
</template>

<script>
    export default {
        name: 'Testimonials',
        props: ['testimonials', 'params'],
        computed: {
            category() {
                return this.testimonials && this.testimonials.category ? this.testimonials.category : false;
            },
            contents() {
                return this.testimonials && this.testimonials.contents ? this.testimonials.contents : false;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>