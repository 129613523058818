<template>
    <div>
        <!-- this is the header -->
        <Header 
            :menus="menus"
            :params="params"
        />

        <!-- Page Header Start -->
        <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">404 Error</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        404 Error
                    </li>
                </ol>
                </nav>
            </div>
        </div>
        <!-- Page Header End -->

        <!-- 404 Start -->
        <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div class="container text-center">
                <div class="row justify-content-center">
                    <div class="col-lg-6">
                        <i class="bi bi-exclamation-triangle display-1 text-primary"></i>
                        <h1 class="display-1">404</h1>
                        <h1 class="mb-4">Page Not Found</h1>
                        <p class="mb-4">
                            We’re sorry, the page you have looked for does not exist in our
                            website! Maybe go to our home page?
                        </p>
                        <router-link
                            to="/"
                            class="btn btn-primary py-3 px-5"
                        >
                            Go Back To Home
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- 404 End -->

        <!-- Footer included here -->
        <Footer 
            :params="params"
            :services="services"
        />
    </div>
</template>
<script>
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'

    export default {
        name: 'ErrorPage',
        props: ['params', 'menus', 'services'],
        components: {
            Header,
            Footer
        }
    }
</script>

<style lang="scss" scoped>

</style>