<template>
    <!-- About Start -->
    <div class="container-xxl py-5" v-if="params">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              class="position-relative overflow-hidden rounded ps-5 pt-5 h-100"
              style="min-height: 400px"
            >
              <img
                class="position-absolute w-100 h-100"
                src="/img/about.png"
                alt=""
                style="object-fit: cover"
              />
              <div
                class="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                style="width: 200px; height: 200px"
              >
                <div
                  class="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3"
                >
                  <h1 class="text-white mb-0" v-if="params.feature_hours">{{ params.feature_hours }}</h1>
                  <h2 class="text-white">Years</h2>
                  <h5 class="text-white mb-0">Experience</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="h-100">
              <h1 class="display-6 mb-5" v-if="params.call_now">
                {{ params.call_now }}
              </h1>
              <div class="row g-4 mb-4">
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <img
                      class="flex-shrink-0 me-3"
                      src="/img/icon/icon-04-primary.png"
                      :alt="getsiteName"
                    />
                    <h5 class="mb-0">Flexible Insurance Plans</h5>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="d-flex align-items-center">
                    <img
                      class="flex-shrink-0 me-3"
                      src="/img/icon/icon-03-primary.png"
                      :alt="getsiteName"
                    />
                    <h5 class="mb-0">NAICOM <br> Regulated</h5>  
                  </div>
                </div>
              </div>
              <p class="mb-4" v-if="params.partners">{{ params.partners }}</p>
              <div class="border-top mt-4 pt-4">
                <div class="d-flex align-items-center">
                  <img
                    class="flex-shrink-0 rounded-circle me-3"
                    src="/img/profile.png"
                    alt=""
                  />
                  <h5 class="mb-0" v-if="params.phone">Call Us: {{ params.phone }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: 'About',
        props: ['params'],
        computed: {
            ...mapGetters(['getsiteName'])
        }
    }
</script>

<style lang="scss" scoped>

</style>