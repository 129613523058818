<template>
    <div>
        <div v-if="! isLoadingBlade && ! errorPage && params">
            <!-- this is the header -->
            <Header 
                :menus="menus"
                :params="params"
            />

            <!-- Page Header Start -->
            <div
            class="container-fluid page-header py-5 mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            >
            <div class="container py-5">
                <h1 class="display-4 animated slideInDown mb-4">
                    Get Your Quote Today!
                </h1>
                <nav aria-label="breadcrumb animated slideInDown">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        Get Quote Now
                    </li>
                </ol>
                </nav>
            </div>
            </div>
            <!-- Page Header End -->

            <!-- this is the Quote Form -->
            <QuoteForm 
                :params="params"
                :services="services"
            />

            <div class="col-lg-12">
                <section class="section contact-info pb-0" style="margin-bottom: 30px;">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4 col-sm-6 col-md-6">
                                <div class="contact-block mb-4 mb-lg-0">
                                    <i class="icofont-live-support"></i>
                                    <h5>Call Us</h5>
                                    <a 
                                        :href="'tel:'+params.phone"
                                        class="text-muted"
                                    >
                                        {{ params.phone }} 
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-md-6">
                                <div class="contact-block mb-4 mb-lg-0">
                                    <i class="icofont-support-faq"></i>
                                    <h5>Email Us</h5>
                                    <a 
                                        :href="'mailto:'+params.email" 
                                        class="text-muted"
                                    >
                                        {{ params.email }}
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-6 col-md-6">
                                <div class="contact-block mb-4 mb-lg-0">
                                    <i class="icofont-location-pin"></i>
                                    <h5>Location</h5>
                                    <a 
                                        :href="params.gMapLink"
                                        class="text-muted"
                                        target="_blank"
                                    >
                                        {{ params.address }}
                                    </a> 
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <!-- Footer included here -->
            <Footer 
                :params="params"
                :services="services"
            />
        </div>

        <div class="row" v-if="isLoadingBlade">
            <!-- Spinner Start -->
            <div
                id="spinner"
                class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            >
                <div class="spinner-grow text-primary" role="status"></div>
            </div>
            <!-- Spinner End -->
        </div>

        <ErrorPage 
            :menus="menus"
            :params="params"
            :services="services"
            v-if="! isLoadingBlade && errorPage"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import Header from '../layouts/Header.vue'
    import Footer from '../layouts/Footer.vue'
    import QuoteForm from '../layouts/homepage/QuoteForm.vue'
    import ErrorPage from '../layouts/ErrorPage.vue'

    export default {    
        name: 'QuotePage',
        components: {
            Header,
            Footer,
            QuoteForm,
            ErrorPage
        },
        data() {
            return {
                isLoadingBlade: true,
                errorPage: false,
                errorMsg: '',
                menus: [],
                params: {},
                services: []
            }
        },
        computed: {
            ...mapGetters(['getsiteName'])
        },
        methods: {
            ...mapActions(['getHomepageData']),
            loadHomepage() {// load homepage data
                this.errorPage = false;
                this.getHomepageData().then((res) => {// get homepage data
                    if(res.status == 200) { // set data
                        this.setData(res.data);
                    } else {
                        this.isLoadingBlade = false;
                        this.errorPage = true
                        this.errorMsg = 'Page was not able to load, reload page...'
                    }
                }).
                catch(() => {
                    this.isLoadingBlade = false;
                    this.errorPage = true
                    this.errorMsg = 'Page was not able to load, reload page...'
                });
            },
            setData(data) {
                this.menus = data.menus;// update menus
                this.params = data.parameters;// set parameters
                this.services = data.services;// get services
                setTimeout(() => { this.isLoadingBlade = false; }, 1000)
            }
        },
        created() {
            this.loadHomepage(); // get page
        }
    }
</script>

<style lang="scss" scoped>

</style>